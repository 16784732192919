<template>
  <div 
    class="wrapper flex flex-row items-start justify-between w-full"
    :class="{ 'map-wrap': isMap }"
  >
    <div
      @click="goToDetail"
      class="image"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    />
    <div class="content flex-1">
      <div class="content-header flex flex-row items-center justify-between">
        <PlaceInfo
          v-if="hasInfo"
          :size="18"
          :id="data.id"
          :subscribe="data.hasUserSubscribePlaces"
          :roomCount="data.hasChatRooms.length"
          :isMap="isMap"
        />
      </div>

      <p @click="goToDetail" class="name">{{ data.title }}</p>

      <div class="flex flex-row items-center">
        <p @click="goToDetail" class="classification mr-2">
          {{ classification }}
        </p>
        <p @click="goToDetail" v-if="data.size > 0" class="size">
          {{ data.size }}평 ({{ size }}m<sup>2</sup>)
        </p>
      </div>

      <div class="flex flex-row items-center place-price">
        <span>보증금</span> <span>{{ utilities.toLocaleString() }}만원</span>
      </div>
      <div class="flex flex-row items-center place-price">
        <span>월</span> <span>{{ leasePrice.toLocaleString() }}만원</span>
      </div>

      <div class="flex flex-row items-center">
        <div
          @click="goToDetail"
          v-if="data.minPrice > 0"
          class="price flex flex-row items-center"
        >
          <span v-if="!isOffer">최저</span>
          <span v-else>제안금</span>
          <span v-if="!isOffer">{{ data.minPrice.toLocaleString() }}원</span>
          <span v-else>{{ price.toLocaleString() }}원</span>
        </div>

        <div
          v-if="hasDate"
          @click="goToDetail"
          class="date flex flex-row items-center ml-4"
        >
          <span>신청일</span>
          <span>{{ date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceInfo from "@/components/PlaceInfo";
import utils from "@/utils";
import dayjs from "dayjs";

export default {
  name: "PlaceSearchItem",
  components: { PlaceInfo },
  props: {
    data: {
      type: Object,
      default: () => ({
        seller: "",
        addr: "",
        title: "",
        size: 0,
        hasPlaceOuterFiles: [],
        hasPlaceInnerFiles: [],
        hasPlaceOtherFiles: [],
        hasUserSubscribePlaces: [],
        hasChatRooms: [],
        minPrice: 0,
      }),
    },
    hasDate: {
      type: Boolean,
      default: false,
    },
    hasInfo: {
      type: Boolean,
      default: true,
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: 0,
    },
    isMap: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToDetail() {
      this.$router.push({ name: "PlaceDetail", params: { id: this.data.id } });
    },
  },
  computed: {
    image: function() {
      const {
        hasPlaceOuterFiles,
        hasPlaceInnerFiles,
        hasPlaceOtherFiles,
      } = this.data;
      if (hasPlaceOuterFiles.length > 0) {
        return utils.getImageUrl(hasPlaceOuterFiles[0]);
      } else if (hasPlaceInnerFiles.length > 0) {
        return utils.getImageUrl(hasPlaceInnerFiles[0]);
      } else if (hasPlaceOtherFiles.length > 0) {
        return utils.getImageUrl(hasPlaceOtherFiles[0]);
      }

      return "";
    },
    date() {
      const { createdAt } = this.data;
      if (createdAt) {
        return dayjs(createdAt).format("YYYY-MM-DD");
      }
      return "";
    },
    size() {
      if (this.data) {
        return this.data.size * 3.3;
      }

      return 0;
    },
    classification() {
      if (this.data.hasPlaceClassification) {
        const { title } = this.data.hasPlaceClassification;
        return title;
      }

      return "";
    },
    utilities() {
      const { utilities: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    leasePrice() {
      const { leasePrice: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  /* height: 137px;
  cursor: pointer;
  padding-left: 17px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 12px;
  margin-bottom: 12px; */

  .image {
    width: 192px;
    height: 137px;
    background-color: #ddd;
    border-radius: 10px;
    margin-right: 23px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    .content-tag {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: left;
      color: #7ca3aa;
    }

    .content-header {
      margin-right: 47px;
    }

    .name {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.8px;
      text-align: left;
      color: #222;
    }

    .classification {
      color: #7ca3aa;
      font-size: 16px;
      font-weight: 500;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .place-price {
      span:first-child {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        color: #616161;
        margin-right: 8px;
      }

      span:last-child {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.72px;
        text-align: left;
        color: #101010;
      }
    }

    .place,
    .size {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      text-align: left;
      color: #737373;
    }

    .price {
      margin-top: 14px;

      span:first-child {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        color: #616161;
        margin-right: 8px;
      }

      span:last-child {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.72px;
        text-align: left;
        color: #101010;
      }
    }

    .date {
      margin-top: 14px;

      span:first-child {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        color: #616161;
        margin-right: 8px;
      }

      span:last-child {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.72px;
        text-align: left;
        color: #101010;
      }
    }
  }
}

@media (max-width: 1279px) {
  .map-wrap {
    flex-direction: column;
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
  }
}

@media (max-width: 1023px) {
  .map-wrap {
    .image {
      width: 120px;
      height: 70px;
      margin: 0 auto 15px;
    }
    .content {
      .name {
        font-size: 12px;
      }
      .classification {
        font-size: 11px;
      }
      .size {
        font-size: 11px;
      }
      .place-price {
        span {
          &:first-child {
            font-size: 12px;
          }
          &:last-child {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .wrapper {
    flex-direction: column;
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
    .content {
      .name {
        font-size: 13px;
      }
      .size {
        font-size: 13px;
      }
      .place-price {
        span {
          &:first-child {
            font-size: 12px;
          }
          &:last-child {
            font-size: 12px;
          }
        }
      }
      .date {
        margin-top: 5px;
        margin-left: 0;
        margin-bottom: 15px;
        span {
          &:first-child {
            font-size: 12px;
          }
          &:last-child {
            font-size: 12px;
          }
        }
      }
    }

    &.map-wrap {
      .image {
        width: 100px;
        height: 50px;
        margin: 0 auto 10px;
      }
      .content {
        .name {
          font-size: 10px;
        }
        .classification {
          font-size: 10px;
        }
        .size {
          font-size: 10px;
        }
        .place-price {
          span {
            &:first-child {
              font-size: 10px;
            }
            &:last-child {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
